@import './common_css/mandatoryFieldLabel.css';
@import './common_css/normalFieldLabel.css';
@import './common_css/loadingOverlay.css';
@import './common_css/cropper.css';
@import './common_css/uploadImage.css';
@import './common_css/HTMLTextEditor.css';
@import './common_css/reactTable.css';

@import './colors.css';
@import './ownerManager.css';

.margin_top_row {
    margin-top: 1rem;
}

.half_margin_top_row {
    margin-top: 0.5rem;
}

.add_activity_button {
    color: var(--primary--color);
    cursor: pointer;
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-right: 1rem;
    float: "right";
}

.edit_header_icon_save {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.custom_error_messages {
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.edit_header_icon_close {
    cursor: pointer;
    font-size: 1.5rem;
    color: grey
}

.custom_checkbox input[type=checkbox] {
    transform: scale(1.5);
}

.edit_header_icon_add {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.error_div {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.parent_info_row {
    margin-bottom: 1rem;
}

.user_license_info {
    font-size: 0.9rem;
}

.license_info_icons {
    font-size: 1.3rem;
    color: var(--primary--color)
}

.license_duration_ok {
    color: var(--primary--color)
}

.license_duration_limit {
    color: var(--pending--color)
}

.license_duration_expired {
    color: var(--declined--color)
}

/*************************************************************************/
/*************************** DATE PICKER *********************************/
/*************************************************************************/
.error-date-picker.e-input-group:not(.e-success):not(.e-warning):not(.e-error),
.test.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
    border-color: #dc3545;
}